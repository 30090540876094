// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$web-app-primary: mat.define-palette(mat.$indigo-palette);
$web-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$web-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $web-app-primary,
      accent: $web-app-accent,
      warn: $web-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-app-theme);

/* You can add global styles to this file, and also import other style files */

// @import "./variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Leckerli+One&family=Mogra&display=swap");
// $white: $color-lighter;

// $theme-colors: (
//   "primary": $color-primary,
//   "secondary": $color-secondary,
//   "success": $color-primary,
//   //"info":       $info,
//   "warning": $color-warning,
//   "danger": $color-danger,
//   "light": $color-lighter,
//   "dark": $color-darker,
// );
/* Importing Bootstrap SCSS file. */
$container-max-widths: (
  sm: 540px,
  md: 720px,
  // lg: 720px,
  // xl: 720px,
  // xxl: 720px,
);

$font-weight-base: 400; // Adjust the base font size to your preference
// $font-size-base: 1.5rem !default; // Set base font size to 14px (14px / 16px = 0.875rem)
// $btn-font-size: 1rem;
$font-size-base: 1rem !default; // Set base font size to 14px (14px / 16px = 0.875rem)

$h1-font-size: 2rem; // 32px
$h2-font-size: 1.375rem; // 22px
$h3-font-size: 1.0625rem; // 17px
$h4-font-size: 1rem; // 16px
$h5-font-size: 0.875rem; // 14px
$h6-font-size: 0.75rem; // 12px
$yellow: #f39e12;
$cyan: #0dc3b7;
$light: #f0f0f3;
$border-radius: 4px;
$input-btn-padding-y: 0.6rem !default;

@import "./variables.scss";
@import "bootstrap/scss/bootstrap";

@media (min-width: 992px) {
  .bd-layout {
    display: grid;
    grid-template-areas: "sidebar main";
    grid-template-columns: 1fr 5fr;
    gap: 1.5rem;
  }
}
.small,
small {
  font-size: 0.875rem;
}
.caption,
caption {
  font-size: 0.75rem;
}
h1 {
  letter-spacing: -0.8px;
  font-weight: 600;
  line-height: 2.25rem;
}
h2 {
  letter-spacing: -0.4px;
  font-weight: 600;
  line-height: 1.5rem;
}
h3 {
  letter-spacing: -0.22px;
  font-weight: 600;
  line-height: 1.5rem;
}
h4 {
  letter-spacing: -0.18px;
  font-weight: 600;
  line-height: 1.25rem;
}
h5 {
  letter-spacing: -0.09px;
  font-weight: 600;
  line-height: 1.25rem;
}
h6 {
  letter-spacing: 0px;
  font-weight: 600;
  line-height: 1rem;
}

// keep the image ratio 1:1
.image-ratio-1 {
  width: 100%; // This makes the container responsive
  padding-top: 100%; // 1:1 aspect ratio (100% of the width
  position: relative;
  .img-fluid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; // Ensures the background image covers the container without stretching
    background-position: center;
  }
}

.text-bg-warning {
  color: #ffffff !important;
}
// .bg-primary-subtle {
//   color: #ffffff !important;
// }
// background-color: (var(--bs-primary-bg-subtle));

.text-bg-info {
  color: #ffffff !important;
}
.is-recommanded {
  font-size: 0.75rem;
  font-weight: 600;
}
.is-popular {
  font-size: 0.75rem;
  font-weight: 600;
}
.img-fluid.rounded {
  border-radius: 0.375rem !important;
}

body {
  // color: $color-lighter;
  // background-color: $color-light;
  font-family: "Inter", sans-serif;
  color: $color-font-body;
}

// .is-rtl {
//   text-align: right;
//   input,
//   select,
//   textarea {
//     text-align: right;
//   }
//   .text-end {
//     text-align: left !important;
//   }
//   .text-start {
//     text-align: right !important;
//   }
//   .float-start {
//     float: right !important;
//   }
//   .float-end {
//     float: left !important;
//   }

//   .dropdown-menu-end[data-bs-popper] {
//     right: auto;
//     left: 0;
//   }
// }

.brand-font {
  span:first-of-type {
    font-family: "Mogra", system-ui;
    font-weight: 400;
    font-style: normal;
  }

  span:not(:first-of-type) {
    font-family: "Leckerli One", cursive;
    font-weight: 400;
    font-style: normal;
  }
}

.text-muted {
  color: var(--color-font-body-subtle) !important;
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

// .router-outlet-content {
//   min-height: calc(100vh - 210px);
//   min-height: calc(100dvh - 210px);
// }

// $infix: null;
// @each $prop, $abbrev in (margin: m, padding: p) {
//   @each $size, $length in (6: 5rem, 7: 7rem, 8: 8rem, 9: 9rem) {
//     .#{$abbrev}#{$infix}-#{$size} {
//       #{$prop}: $length !important;
//     }
//     .#{$abbrev}t#{$infix}-#{$size},
//     .#{$abbrev}y#{$infix}-#{$size} {
//       #{$prop}-top: $length !important;
//     }
//     .#{$abbrev}r#{$infix}-#{$size},
//     .#{$abbrev}x#{$infix}-#{$size} {
//       #{$prop}-right: $length !important;
//     }
//     .#{$abbrev}b#{$infix}-#{$size},
//     .#{$abbrev}y#{$infix}-#{$size} {
//       #{$prop}-bottom: $length !important;
//     }
//     .#{$abbrev}l#{$infix}-#{$size},
//     .#{$abbrev}x#{$infix}-#{$size} {
//       #{$prop}-left: $length !important;
//     }
//   }
// }

.iti {
  width: 100%;
}
.iti__country-list {
  z-index: 3;
  left: 0;
}

div.ng-lazyloaded {
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  &.logo-display {
    background-size: contain !important;
  }
}

.custom-avatar {
  background-color: #000000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  border-radius: 50%;
  padding: 10px 15px;
  text-transform: uppercase;
  &.small {
    padding: 5px 12px;
    font-size: 0.75rem;
    font-weight: 100;
  }
}

// .modal-content {
//   overflow: scroll;
//   .modal-header {
//     border: none;
//   }
//   .modal-body {
//     padding: 1rem 30px;
//   }
//   .btn-close {
//     content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' aria-hidden='true' role='img' class='iconify iconify--la' width='32' height='32' preserveAspectRatio='xMidYMid meet' viewBox='0 0 32 32'><path fill='#FFFFFF' d='M7.219 5.781L5.78 7.22L14.563 16L5.78 24.781l1.44 1.439L16 17.437l8.781 8.782l1.438-1.438L17.437 16l8.782-8.781L24.78 5.78L16 14.563z'></path></svg>");
//   }
// }

.navbar-brand {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* Sombro */
  color: $color-font-body;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

.offcanvas-login,
.offcanvas-order-view-from-card {
  min-height: 40vh;
  min-height: 40dvh;
  height: auto !important;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.offcanvas-50 {
  min-height: 50vh;
  min-height: 50dvh;
  height: auto !important;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.offcanvas-full-screen {
  height: 100vh !important;
  height: 100dvh !important;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.offcanvas-fit-content {
  height: fit-content !important;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.offcanvas-bottom {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.offcanvas-top {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
// branding overrite

.btn-outline-primary {
  color: $color-primary;
  border-color: $color-primary;
}
.btn-primary,
.btn-primary:disabled {
  background-color: $color-primary;
  border-color: $color-primary;
}
.btn-primary,
.btn-outline-primary {
  &:hover,
  &:active,
  &:focus-visible,
  &:first-child:active,
  &.active {
    background-color: $color-primary-light;
    border-color: $color-primary-light;
  }
}

.btn-link {
  color: $color-primary;
}

.border-primary {
  border-color: $color-primary !important;
}

.btn-outline-secondary {
  color: $color-secondary;
  border-color: $color-secondary;
}
.btn-secondary {
  background-color: $color-secondary;
  border-color: $color-secondary;
}
.border-secondary {
  border-color: $color-secondary !important;
}

.text-bg-primary {
  background-color: $color-primary !important;
}
.text-bg-primary {
  background-color: $color-primary !important;
}
.text-bg-secondary {
  background-color: $color-secondary !important;
}

.text-primary {
  color: $color-primary !important;
}

.text-secondary {
  color: $color-secondary !important;
}

.form-check-input:checked {
  background-color: $color-primary;
  border-color: $color-primary;
}

.mat-mdc-slider .mdc-slider__track--inactive {
  background-color: $color-primary !important;
}

.mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: $color-primary !important;
}

.mat-mdc-slider .mdc-slider__thumb-knob {
  background-color: $color-primary !important;
  border-color: $color-primary !important;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  color: $color-body;
  background-color: $color-font-body;
}

.list-group-item.active {
  background-color: $color-font-body;
  border-color: $color-font-body;
  color: $color-body;
}

.navbar-brand:hover,
.navbar-brand:focus {
  color: $color-primary;
}

.bg-body-header {
  background-color: $color-body !important;
  filter: brightness(0.95); /* Adjust the value to control brightness */
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: $color-body;
  background-color: $color-primary;
  border-color: $color-primary;
}

// .btn {
//   font-size: 1.2rem;
// }
// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) {
//   .btn {
//     font-size: 1rem;
//   }
// }

.offcanvas-main-category {
  a {
    text-decoration: none !important;
    color: initial;
  }
}

.offcanvas-backdrop-after-nav {
  height: calc(100vh - 60px);
  height: calc(100dvh - 60px);
  z-index: 1020;
}
.offcanvas-after-nav {
  bottom: 60px;
  z-index: 1021;
  max-height: calc(100vh - 60px) !important;
  max-height: calc(100dvh - 60px) !important;
}

.badge {
  font-weight: 600;
  // line-height: 1rem;
  border-radius: 2px;
}

hr {
  background-color: var(--bg-hr-subtle);
  color: var(--bg-hr-subtle);
  opacity: 1;
}

.is-rtl {
  .icon-arrow-go-back,
  .icon-arrow-left,
  .icon-arrow-right {
    transform: rotatey(190deg);
  }
}
.form-control {
  background-color: var(--bg-input-subtle);
  font-size: 0.875rem;
  &:focus {
    // color: var(--bs-body-color);
    background-color: var(--bg-input-subtle);
    border-color: none;
  }
  ::placeholder {
    font-size: 0.875rem;
  }
  // &:internal-autofill-selected {
  //   background-color: var(--bg-input-subtle); !important;
  // }
}

.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  background-color: var(--bg-input-subtle);
}

.form-floating > label {
  font-size: 0.875rem;
  color: var(--color-font-body-subtle);
}

.navbar.fixed-bottom {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  background-color: var(--color-body);
}

.navbar.fixed-top {
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.25);
  background-color: var(--color-body);
}
