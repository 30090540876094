$color-primary: var(--color-primary, #62d2c8);
$color-primary-light: var(--color-primary-light, #7acec7);
$color-secondary: var(--color-secondary, #e4667d);
$color-secondary-light: var(--color-secondary-light, #f77c93);

$color-danger: var(--color-danger, #dc3545);
$color-danger-light: var(--color-danger-light, #f8d7da);

$color-darker: var(--color-darker, #141e42);
$color-dark: var(--color-dark, #202c59);
$color-medium: var(--color-medium, #878fb1);
$color-light: var(--color-light, #f4f4f4);
$color-lighter: var(--color-lighter, #ffffff);


$color-font-body: var(--color-font-body, #0a4d61);
$color-body: var(--color-body, #FFFFFF);
